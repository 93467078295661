<template>
<div class="card">
    <div class="card-header border-0 pt-6 bg-white row m-0 sticky">
        <div class="d-flex p-0">
            <div class="card-title row w-100">
                <div class="d-flex p-0 align-items-center position-relative mb-4">
                    <span class="text-gray-500 fw-bolder text-hover-primary rounded-1 cursor-pointer me-6 fs-6" @click="router.go(-1)"><i class="fas fa-arrow-left me-2 text-gray-500 fw-bolder"></i>BACK</span>
                </div>
                <div class="d-flex flex-column p-0 position-relative mb-4">
                    <span>Create Product Planning</span>
                </div>
            </div>
        </div>
    </div>
    <div class="card-body tab-content text-gray-700" id="tab_raw_product">
        <div class="mb-10">
            <el-form
                ref="formRef"
                :model="tempData"
                class="form w-100 mb-7"
            >
                <div class="fv-row mb-7">
                    <label class="fs-6 fw-bold mb-2 required">Vendor</label>
                    <el-form-item
                        class="fv-row mb-7"
                        prop="vendor_id"
                        :rules="[
                          { required: true, message: 'Vendor is required' },
                        ]"
                    >
                        <el-select
                            v-model="tempData.vendor_id"
                            filterable
                            allow-create
                            default-first-option
                            :reserve-keyword="false"
                            placeholder="Masukkan vendor"
                            name="vendor_id"
                        >
                            <el-option
                              v-for="(item, i) in listVendor"
                              :key="i"
                              :label="item.nama_vendor"
                              :value="item.id"
                            />
                        </el-select>
                    </el-form-item>
                </div>
                <div class="fv-row mb-7">
                    <button type="button" class="btn btn-primary" @click="modalClick()">Cari Artikel</button>
                </div>
                <div class="fv-row mb-7">
                  <label class="required fs-6 fw-bold mb-2">Judul</label>
                  <el-form-item
                    class="fv-row mb-7"
                    prop="title"
                    :rules="[
                      { required: true, message: 'Judul is required' },
                    ]"
                  >
                    <el-input v-model="tempData.title" name="title" class="form-control p-0 border-0" placeholder="Judul" size="large"></el-input>
                  </el-form-item>
                </div>
            </el-form>
        </div>

        <div class="fv-row mb-7">
            <label class="required fs-6 fw-bold mb-2 col-auto">List product</label>
            <el-popconfirm
                confirm-button-text="OK"
                cancel-button-text="No"
                title="Hapus semua produk?"
                @confirm="tempData.item.splice(0)"
            >   
                <template #reference>
                    <button class="btn btn-sm btn-danger ms-3 cursor-pointer">Hapus Semua</button>
                </template>
            </el-popconfirm>
            <div ref="tableRef" class="table-fixed-header">
                <table class="table table-rounded table-row-dashed cursor-pointer table-hover" :class="{'overlay': loadingTable, 'overlay-block': loadingTable}">
                    <thead>
                        <tr class="text-start fw-bolder fs-7 gs-0 text-gray-400 border-gray-200 text-uppercase">
                            <th class="w-50px">#</th>
                            <th>Nama</th>
                            <th>Artikel</th>
                            <th>SKU</th>
                            <th>Kategori</th>
                            <th>Color</th>
                            <th>Size</th>
                            <th class="text-end">Qty</th>
                            <th class="text-end">Action</th>
                        </tr>
                    </thead>
                    <tbody class="fw-bold text-gray-600">
                        <tr v-for="(item, i) in tempData.item" :key="i">
                            <td class="w-50px">{{ item.index }}</td>
                            <td>{{ item.name }}</td>
                            <td>{{ item.artikel }}</td>
                            <td>{{ item.sku }}</td>
                            <td>{{ item.raw_category }}</td>
                            <td>{{ item.color }}</td>
                            <td>{{ item.size }}</td>
                            <td class="text-end">
                                <div class="row justify-content-end">
                                    <input type="number" class="form-control w-100px" placeholder="Qty" v-model="item.qty" :name="'qty'+item.index" />
                                    <div class="fv-plugins-message-container" v-if="!item.qty || item.qty <= 0">
                                        <div class="fv-help-block">
                                            <span class="text-danger" :name="'qty'+item.index">Wajib diisi</span>
                                        </div>
                                    </div>
                                </div>
                            </td>
                            <td scope="col" class="text-end w-150px">
                                <div class="d-flex justify-content-end">
                                    <el-popconfirm
                                        confirm-button-text="OK"
                                        cancel-button-text="No"
                                        :icon="InfoFilled"
                                        title="Are you sure to delete this?"
                                        @confirm="onDelete(i)"
                                    >
                                        <template #reference>
                                            <button class="btn btn-danger btn-sm ms-1" data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end" data-kt-menu-flip="top-end">
                                                <i class="fas fa-trash-alt"></i>
                                            </button>
                                        </template>
                                    </el-popconfirm>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                    <div class="overlay-layer card-rounded bg-dark bg-opacity-5" v-if="loadingTable">
                        <div class="spinner-border text-primary" role="status">
                            <span class="visually-hidden">Loading...</span>
                        </div>
                    </div>
                </table>
            </div>
        </div>

        <div class="row justify-content-center">
            <button
                type="submit"
                @click="onSubmit(formRef)"
                class="btn btn-primary w-350px mt-7 justify-content-end"
                :data-kt-indicator="loadingButton ? 'on' : 'off'"
            >
                <span class="indicator-label"> Submit </span>

                <span class="indicator-progress">
                    Please wait...
                    <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                </span>
            </button>
        </div>

    </div>

    <el-dialog v-model="modalVisible.artikel" :title="modalTitle" :close-on-click-modal="true" width="800px">
        <div class="filter position-relative mb-4 fs-6 fw-bolder text-gray-600" ref="searchRef">
            <div class="filter-item">
                <span>Search</span>
                <el-input v-model="artikel" placeholder="Search artikel" clearable />
            </div>
            <div class="d-flex">
                <button class="btn btn-icon btn-light-primary me-3" @click="onArtikelFilter()"><i class="fas fa-search"></i></button>
                <button class="btn btn-icon btn-light-danger" @click="resetArtikelFilter()"><i class="fas fa-times"></i></button>
            </div>
        </div>
        <div class="table-responsive table-fixed-header">
            <table class="table table-rounded table-row-dashed table-hover" :class="{'overlay': loadingTable, 'overlay-block': loadingTable}">
                <thead>
                    <tr class="text-start fw-bolder fs-7 gs-0 text-gray-400 border-gray-200 text-uppercase">
                        <th>#</th>
                        <th class="w-125px">Artikel</th>
                        <th class="w-150px">SKU</th>
                        <th class="w-150px">Kategori</th>
                        <th>Warna</th>
                        <th class="w-75px">Ukuran</th>
                        <th class="text-center w-75px">Status</th>
                        <th class="text-end">Action</th>
                    </tr>
                </thead>
                <tbody class="fw-bold text-gray-600">
                    <tr v-for="(item, i) in listArtikel" :key="i" class="cursor-pointer" title="Pilih artikel" @click="getSKU(item)">
                        <td scope="row">{{ item.index }}</td>
                        <td>{{ item.artikel }}</td>
                        <td>{{ item.sku }}</td>
                        <td>{{ item.raw_category }}</td>
                        <td>{{ item.color }}</td>
                        <td>{{ item.size && item.size.toString().replaceAll(',', ', ') }}</td>
                        <td class="text-center">
                            <i v-if="tempData.item.find(o => o.sku == item.sku)" class="fas fa-check text-success"></i>
                            <i v-else class="fas fa-times text-danger"></i>
                        </td>
                        <td class="text-end">
                            <button class="btn btn-primary btn-sm ms-1" data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end" data-kt-menu-flip="top-end" @click.stop="getSKU(item)">
                                <i class="fas fa-plus"></i>
                            </button>
                        </td>
                    </tr>
                </tbody>
                <div class="overlay-layer card-rounded bg-dark bg-opacity-5" v-if="loadingTable">
                    <div class="spinner-border text-primary" role="status">
                        <span class="visually-hidden">Loading...</span>
                    </div>
                </div>
            </table>
        </div>
        <template #footer>
            <div class="text-center">
                <el-pagination 
                    background 
                    :page-size="50"
                    layout="prev, pager, next" 
                    :page-count="pagination.total_page" 
                    @next-click="handlePageChange" 
                    @prev-click="handlePageChange" 
                    @current-change="handlePageChange" 
                    @update:current-page="pagination.page"
                />
            </div>
        </template>
    </el-dialog>
</div>
</template>

<style scoped lang="scss">
    $color_primary: #EE7305;
    $add_product_size: 180px;

    .product-distribusi {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(auto, $add_product_size));
        text-align: left;
        position: relative;
        height: 100%;
        overflow-y: scroll;
        padding: .5rem;
        gap: 1rem;
        grid-auto-rows: minmax(min-content, max-content);

        .list-product-distribusi {
            display: flex;
            flex-direction: column;

            img, span {
                margin-bottom: .5rem;
            }

            img {
                border: solid 1px #eee;
                width: 100%;
                height: $add_product_size;
            }

            span {
                text-transform: uppercase;
                font-weight: 500;
            }
        }

        .list-product-distribusi.add-product-distribusi {
            height: $add_product_size;
            align-items: center;
            border: dashed 3px $color_primary;
            cursor: pointer;

            i {
                color: $color_primary !important;
                font-size: 200%;
                line-height: $add_product_size;
                width: 100%;
                text-align: center;
            }
        }

        .list-product-distribusi.add-product-distribusi:hover {
            border-style: solid;
        }
    }
</style>

<script>
import { defineComponent, onBeforeMount, onMounted, ref, reactive, watchEffect, watch, computed, onUpdated } from "vue";
import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { formatIDR, validFileSize, validFileType } from "@/helpers/functions";
//import TableLite from 'vue3-table-lite/src/components/TableLite.vue'
import Datatable from "@/components/kt-datatable/KTDatatable.vue"
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import * as Yup from "yup";
import { ErrorMessage, Field, Form } from "vee-validate";
import { ElLoading, Elpagination, FormInstance, FormRules} from 'element-plus'
//import { InfoFilled } from '@element-plus/icons-vue'
import { useRoute, useRouter } from 'vue-router'
import { Actions } from "@/store/enums/StoreEnums";
import { useStore } from 'vuex'


export default defineComponent({
    components: { 
        //Datatable,
        //Field, 
        //Form,    
        //ErrorMessage, 
    },
    setup() {
        const route = useRoute()
        const router = useRouter()

        const store = useStore()

        const img_url_products = ref(process.env.VUE_APP_IMAGE_PRODUCTS)
        const img_url_desain= ref(process.env.VUE_APP_IMAGE_DESAINS)
        const loadingTable = ref(true);
        
        const loadingButton = ref(false);
        const disabledButton = ref(true)
        const submitButton = ref(null)

        const modalVisible = ref({
            artikel: false,
        })
        const modalTitle = ref('')

        const formRef = ref(null)
        const search = ref(null)
        const sku = ref(null)
        const artikel = ref(null)

        const tempData = reactive({
            title: null,
            vendor_id: null,
            item: [],
        })

        const listVendor = computed(() => store.getters.getListVendor);
        const listArtikel = reactive([])

        const onArtikelFilter = async () => {
          await getArtikel()
        }

        const resetArtikelFilter = async () => {
            await getArtikel()
            artikel.value = null
        }

        // PAGINATION
        const pagination = ref({
            per_page: 50,
            total_page: 0,
            total_row: 0,
            page: 1,
        })

        const handlePageChange = async (page) => {
            loadingTable.value = true

            await getArtikel(page)
            loadingTable.value = false
        }

        const onSubmit = async (formEl) => {
            if (!formEl) return

            await formEl.validate( async(valid, fields) => {
                loadingButton.value = true

                try {
                    if (valid) {

                        if(tempData.item.some(o => !o.qty || o.qty <= 0)) {
                            throw new Error('Quantity wajib diisi')
                        }

                        await ApiService.post("supplier/create_request_po", { title: tempData.title, vendor_id: tempData.vendor_id, data: tempData.item })
                        .then(({ data }) => {
                            if(data.error) {
                                throw new Error(data.messages)
                            }

                            Swal.fire({
                                title: data.messages,
                                icon: "success",
                                showConfirmButton: false,
                                timer: 2000
                            });

                            tempData.title = null
                            tempData.item.splice(0)
                            router.push({name: 'request_po'})
                        })
                        .catch(({ response }) => {  
                            if(response.data) {
                                Swal.fire({
                                    title: response.data.messages,
                                    icon: "error",
                                    showConfirmButton: false,
                                    timer: 3000
                                });
                            }
                        });

                    } else {
                        Swal.fire({
                            title: "Please check form before submit",
                            icon: "error",
                            showConfirmButton: false,
                            timer: 3000
                        });
                        throw new Error('Please check form before submit.')
                    }

                    loadingButton.value = false
                
                } catch(e) {
                    Swal.fire({
                        title: e.message,
                        icon: "error",
                        showConfirmButton: false,
                        timer: 3000
                    });

                }

                loadingButton.value = false
            })
        }

        const onDelete = async (index) => {
            try {
                tempData.item.splice(index, 1)

            }
            catch(e) {
                Swal.fire({
                    title: 'Terjadi error',
                    icon: "error",
                    showConfirmButton: false,
                    timer: 3000
                });

            }

            disabledButton.value = true
            loadingButton.value = false
        }

        const modalClick = async() => {
            const loading = ElLoading.service({ text: 'Tunggu', })

            try {
                if(!tempData.vendor_id) {
                    throw new Error('Harap pilih vendor terlebih dahulu')
                }

                await getArtikel()

            } catch (e) {
                Swal.fire({
                    title: e.message,
                    icon: "error",
                    buttonsStyling: false,
                    showConfirmButton: false,
                    timer: 2000,
                })
            }

            loading.close()
        }

        const getArtikel = async(page = 1) => {
            const loading = ElLoading.service({ text: 'Tunggu', })

            try {
                if(!tempData.vendor_id) {
                    throw new Error('Harap pilih vendor terlebih dahulu')
                }

                let type = '&type=item';
                let tipe_produk = '&tipe_produk=MTO';
                let valArtikel = artikel.value ? '&artikel=' + artikel.value : '';
                let vendor = '&vendor=' + tempData.vendor_id;

                await ApiService.get("product_raw?page=" + page + type + tipe_produk + valArtikel + vendor)
                    .then(({ data }) => {
                        if(data.data.data.length == 0) {
                            throw Error('Tidak ada data')
                        }

                        pagination.value = data.data.pagination
                        Object.assign(listArtikel, data.data.data)

                        modalTitle.value = 'List Artikel - '+ listArtikel[0].nama_vendor
                        modalVisible.value.artikel = true
                    })
                    .catch((err) => {
                        Swal.fire({
                            title: err,
                            icon: "error",
                            buttonsStyling: false,
                            showConfirmButton: false,
                            timer: 2000,
                        })
                    });

            } catch (e) {
                Swal.fire({
                    title: e.message,
                    icon: "error",
                    buttonsStyling: false,
                    showConfirmButton: false,
                    timer: 2000,
                })
            }

            loading.close()
        }

        const addSKU = async() => {
            if(sku.value) {
                const loading = ElLoading.service({ text: 'Tunggu', })

                try {
                    let type = '&type=item';
                    let tipe_produk = '&tipe_produk=MTO';
                    let valSKU = '&sku=' + sku.value;

                    await ApiService.get("product_raw?" + type + tipe_produk + valSKU)
                        .then(({ data }) => {
                            if(data.data.data.length > 0) {

                                if(tempData.item.find(o => o.sku == data.data.data[0].sku)) {
                                    throw('Produk sudah ditambahkan')
                                }

                                tempData.item.push(data.data.data[0])
                            } else {
                                throw('SKU not found')
                            }
                        })
                        .catch((err) => {
                            Swal.fire({
                                title: err,
                                icon: "error",
                                buttonsStyling: false,
                                showConfirmButton: false,
                                timer: 2000,
                            })
                        });

                } catch (e) {
                    Swal.fire({
                        title: e.message,
                        icon: "error",
                        buttonsStyling: false,
                        showConfirmButton: false,
                        timer: 2000,
                    })
                }

                loading.close()
            }
        }

        const getSKU = async(item) => {
            const loading = ElLoading.service({ text: 'Tunggu', })

            try {
                if(!item) {
                    throw new Error('Data not found')
                } 

                if(tempData.item.find(o => o.sku == item.sku)) {
                    throw new Error('Produk sudah ditambahkan')
                }

                tempData.item.push(item);
                sku.value = item.sku

            } catch (e) {
                Swal.fire({
                    title: e.message,
                    icon: "error",
                    buttonsStyling: false,
                    showConfirmButton: false,
                    timer: 2000,
                })
            }

            loading.close()
        }


        onBeforeMount(() => {
            setCurrentPageTitle(route.meta.title);
            setCurrentPageBreadcrumbs('Supplier', [route.meta.title]);

            store.dispatch(Actions.LIST_VENDOR);

            if(submitButton.value) {
                submitButton.value.disabled = true;
            }
        })

        return {
            route, router,
            img_url_desain, img_url_products, modalClick, modalVisible, modalTitle, disabledButton, loadingButton, submitButton,
            pagination, handlePageChange, 
            formRef,
            listArtikel, onArtikelFilter, resetArtikelFilter, listVendor,
            sku, artikel, tempData, 
            addSKU, getSKU, onDelete, onSubmit
        }
    }
})
</script>